function handleScroll() {
    const navbar = document.querySelector('.navbar-2');
  
    if (window.scrollY > 0) {
      navbar.style.backgroundColor = 'var(--dark-yellow)';
      navbar.style.marginTop = '0';
    } else {
      navbar.style.backgroundColor = 'rgba(221, 221, 221, 0)';
      navbar.style.marginTop = '10px';
    }
  
    navbar.style.transition = 'background-color 0.3s ease, margin-top 0.3s ease';
  }
  
  window.addEventListener('scroll', handleScroll);
  