var recaptchaScriptLoaded = false;

function loadRecaptchaScript() {
    if (recaptchaScriptLoaded) return;

    // Check for the form by ID and render reCAPTCHA for the first one found.
    var form = document.getElementById('contact-form') || document.getElementById('rating-form');
  
    if (!form) {
        // If no form is present, don't log an error, just return.
        return;
    }

    window.recaptchaCallback = function() {
        grecaptcha.render('recaptcha-container', { 
            'sitekey' : '6Ld-yMYpAAAAANrxNJEF-GybgJIWRfTR25XZwPy6' 
        });
    }

    // Create a script element for reCAPTCHA
    var script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?onload=recaptchaCallback&render=explicit';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    recaptchaScriptLoaded = true;
}

// Attach the event listener to both forms if they exist
var contactForm = document.getElementById('contact-form');
var ratingForm = document.getElementById('rating-form');

if (contactForm || ratingForm) {
    var targetForm = contactForm || ratingForm;
    targetForm.addEventListener('mouseenter', loadRecaptchaScript, { once: true });
    targetForm.addEventListener('touchstart', loadRecaptchaScript, { once: true });
}
