function smoothScrollToAnchor() {
    const hash = window.location.hash;
    if (hash) {
        // Add a slight delay to ensure the browser has settled after hash change
        setTimeout(() => {
            const anchorElement = document.querySelector(hash);
            if (anchorElement) {
                // Determine the appropriate navbar height based on screen size
                const isMobile = window.innerWidth <= 768;
                const navbarHeight = isMobile ? 90 : 90;
                
                // Calculate the offset position for smooth scrolling
                const elementPosition = anchorElement.getBoundingClientRect().top;
                const offsetPosition = window.pageYOffset + elementPosition - navbarHeight;
                
                // Scroll to the calculated position
                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
            }
        }, 100);
    }
}

window.addEventListener('load', smoothScrollToAnchor);
window.addEventListener('hashchange', smoothScrollToAnchor);