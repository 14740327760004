document.addEventListener("DOMContentLoaded", function() {
    var swiper = new Swiper('.mySwiper', {
        slidesPerView: 'auto',
        spaceBetween: 15,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        loop: true,
        grabCursor: true,
    });
});
