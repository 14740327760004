document.addEventListener('DOMContentLoaded', () => {
    const counters = document.querySelectorAll('.text-block-11');
    const animationDuration = 2000; // Duration of the animation in milliseconds

    const animateCounter = (element, targetValue) => {
        let startValue = 0;
        let startTime = null;
        const hasPlusSign = targetValue.includes('+');
        const numericValue = parseInt(targetValue); // Extract the numeric part only

        const step = (currentTime) => {
            if (!startTime) startTime = currentTime;
            const progress = Math.min((currentTime - startTime) / animationDuration, 1);
            const currentValue = Math.floor(progress * (numericValue - startValue) + startValue);
            element.textContent = currentValue + (hasPlusSign ? ' +' : '');

            if (progress < 1) {
                window.requestAnimationFrame(step);
            } else {
                element.textContent = targetValue; // Ensure it ends on the exact target value, including the '+'
            }
        };

        window.requestAnimationFrame(step);
    };

    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const targetValue = entry.target.textContent.trim();
                animateCounter(entry.target, targetValue);
                observer.unobserve(entry.target); // Stop observing after animation
            }
        });
    }, {
        threshold: 0.5 // Adjust as needed to trigger animation when the counter is half visible
    });

    counters.forEach(counter => {
        observer.observe(counter);
    });
});
